<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <el-input v-model="searchForm.adminName" placeholder="操作人" class="mgB10 custom-form-input"></el-input>
      <el-input v-model="searchForm.liangName" placeholder="粮库名称" class="mgL10 mgB10 custom-form-input"></el-input>
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border v-loading="loading">
        <el-table-column type="index" header-align="center" align="center" label="序号" width="50"></el-table-column>
        <el-table-column prop="liangName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="粮库名称">
        </el-table-column>
        <el-table-column prop="adminName" :show-overflow-tooltip="true" header-align="center" align="center"
          label="操作人"></el-table-column>
        <el-table-column prop="expireTime" :show-overflow-tooltip="true" header-align="center" align="center"
          label="续订前时间">
        </el-table-column>
        <el-table-column prop="afterExpireTime" :show-overflow-tooltip="true" header-align="center" align="center"
          label="续订后时间"></el-table-column>
        <el-table-column prop="renewTime" :show-overflow-tooltip="true" header-align="center" align="center"
          label="操作时间"></el-table-column>
      </el-table>
      <table-page :page="searchForm.page" :limit="searchForm.limit" :total="total" @search-table="feach">
      </table-page>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searchForm: {
          adminName: "",
          liangName: "",
          page: 1,
          limit: 10,
        },
        loading: false,
        tableData: [],
        total: 0,
      }
    },
    mounted() {
      let name=this.$route.query.name;
      if(name) this.searchForm.liangName=name
      this.search();
    },
    methods: {
      //获取数据
      getData(page, limit) {
        this.loading = true;
        this.$axios.Get(this.$api.depotRenewRecord, {
          adminName: this.searchForm.adminName,
          liangName: this.searchForm.liangName,
          page: page,
          limit: limit
        }).then(res => {
          this.searchForm.limit = limit
          this.searchForm.page = page
          this.tableData = res.data;
          this.total = res.count
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      //搜索
      search() {
        this.getData(1, this.searchForm.limit)
      },
      //分页
      feach(e) {
        this.getData(e.page, e.limit)
      },
    },
    components: {
      "table-page": () => import('@/components/TablePage')
    }
  }
</script>

<style scoped lang="less">
</style>
